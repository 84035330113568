<template>
  <v-container>
    <v-row class="mb-4">
      <h2>Feedback to Psychological Disorders Post - Part 1</h2>
    </v-row>
    <v-form v-for="question in questions" :key="question.inputName">
      <div class="mb-4">Example Student Post:</div>
      <p class="mb-8 mt-8">"{{ examplePosts[0].text }}"</p>
      <v-row class="mb-4 mt-4">
        {{ question.text }}
      </v-row>
      <div>
        <s-textarea v-model="inputs[question.inputName]" class="mb-4" outlined />
      </div>
    </v-form>
  </v-container>
</template>

<script>
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'UPEIPsych1020A6Task3Part1',
  components: {STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        feedbackAnswer: null,
      },
      questions: [
        {
          text: 'Please provide appropriate feedback to this example post made by a student about Psychological Disorders.',
          inputName: 'feedbackAnswer',
        },
      ],
      examplePosts: [
        {
          postNumber: 0,
          text: "After I watched this week's assigned videos, the One video that stood out to me the most, was Eleanor Longden's ted talk \"The voices in my head\". As someone who doesn't know much about schizophrenia, I found Elizabeth did a great job explaining and describing the life of somebody who is suffering from schizophrenia, as well as her own story and experiences dealing with schizophrenia. I have always been curious and intrigued by this mental illness, and have always wanted to learn more about the cause and what part of the brain is associated with it, and why only some people have it. After watching Eleanor's video I am even more interested and plan on doing my own research to learn more about this interesting illness.cI connected Elenors story with a video I watched in 10th-grade social studies. It was about a man who kept hearing voices in his head and couldn't get the help he needed and these voices eventually drove him to shoot and kill someone. He was not criminally responsible due to the fact he had schizophrenia. His name was Jeffery Arenburg, he was a Canadian from nova-scotia who killed an ex-hockey player and former reporter Brian Smith. Similar to Eleanor, her voice leads her to dump a glass of water on a teacher which is obviously not as serious as shooting somebody, but who knows what could've happened if she didn't get the help she needed. I think schizophrenia is a very serious mental illness, that needs to be taken more seriously. Actions need to be put in place to ensure people can get the help they need to be their best selves and hopefully avoid any problems they might possibly encounter. Like dumping water on a teacher's head or in more serious cases shooting or killing somebody.  ",
        },
      ],
    };
  },
};
</script>
